<style lang="scss" scoped>
</style>
<template>
  <el-dialog title="編輯模組" v-model="state.dialogFormVisible" width="400px">
    <el-form>
      <el-form
        :model="state.form"
        :ref="(el) => (state.addFrom = el)"
        :rules="state.addRules"
      >
        <div class="flex_line_c">
          <el-form-item
            label="用戶名"
            :label-width="state.formLabelWidth"
            prop="name"
            v-if="!props.isEditPass"
          >
            <el-input
              v-model="state.form.name"
              autocomplete="off"
              placeholder="請輸入用戶名"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="名稱"
            :label-width="state.formLabelWidth"
            prop="name"
            v-if="props.name === '' || props.isEditPass"
          >
            <el-input
              v-model="state.form.name"
              autocomplete="off"
              placeholder="請輸入模組名稱"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="電話"
            :label-width="state.formLabelWidth"
            prop="phone"
            v-if="!props.isEditPass"
          >
            <el-input
              v-model="state.form.phone"
              autocomplete="off"
              placeholder="請輸入電話"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="積分"
            :label-width="state.formLabelWidth"
            prop="integral"
            v-if="props.name !== '' && !props.isEditPass"
          >
            <el-input
              v-model="state.form.integral"
              autocomplete="off"
              placeholder="請輸入積分"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="狀態"
            :label-width="state.formLabelWidth"
            prop="status"
          >
            <el-select v-model="state.form.status" placeholder="">
              <el-option label="正常" value="1"></el-option>
              <el-option label="禁用" value="0"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="state.dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="add_submit">確 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, watch, getCurrentInstance } from "vue";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    integral: {
      type: Number,
      default: 0,
    },
    phone: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    isEditPass: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: [Number, String],
      default: "",
    },
  },
  emits: {
    toggleDialogVisible(data) {
      return data;
    },
    reloadData(data) {
      return data;
    },
  },
  setup(props, { emit }) {
    // 变量

    // 表单验证
    const validatePass = (rule, value, callback) => {
      // 密碼正則校驗
      if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/.test(value)) {
        callback(new Error("密碼至少包含數字和英文,6-18個字符"));
      } else {
        callback();
      }
    };

    const validatePhone = (rule, value, callback) => {
      // 手機號碼正則校驗
      if (
        !(
          /^([5|6|8|9|2|3])\d{7}$/.test(value) ||
          /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57]|19[0-9])[0-9]{8}$/.test(
            value
          )
        )
      ) {
        callback(new Error("請輸入正確的號碼"));
      } else {
        callback();
      }
    };
    // state
    const state = reactive({
      dialogFormVisible: props.dialogVisible,
      form: {
        name: props.name,
        pass: "",
        phone: props.phone,
        integral: props.integral,
        status: "1",
      },
      addFrom: "",
      formLabelWidth: "70px",
      addRules: {
        name: [{ required: true, message: "請輸入模組名稱", trigger: "blur" }],
        pass: [{ required: true, message: "請輸入模組名稱", trigger: "blur" }],
        phone: [
          { required: true, message: "請輸入電話", trigger: "blur" },
          {
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        integral: [{ required: true, message: "請輸入積分", trigger: "blur" }],
        status: [{ required: true, message: "請選擇狀態", trigger: "blur" }],
      },
    });

    // 监听
    watch(
      () => props.dialogVisible,
      (val, old) => {
        if (val) state.dialogFormVisible = val;
      }
    );

    watch(
      () => state.dialogFormVisible,
      (val, old) => {
        if (!val) emit("toggleDialogVisible", true);
      }
    );

    watch(
      () => props.name,
      (val, old) => {
        state.form.name = val;
      }
    );

    watch(
      () => props.phone,
      (val, old) => {
        state.form.phone = val;
      }
    );

    watch(
      () => props.integral,
      (val, old) => {
        state.form.integral = val;
      }
    );

    // 生命周期
    // created
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $message, $http } = proxy;

    // methods

    const add_submit = () => {
      if (props.isEditPass) {
        // 修改密碼
        state.addFrom.validate(async (valid) => {
          if (!valid) return;

          const res = await $http.updateMenu({
            menu_name: state.form.name,
            id: props.userId,
            status: state.form.status,
          });
          if (res.status !== 200) return;
          $message.success("修改成功");
          window.location.reload();
          state.form = {
            name: "",
            pass: "",
            phone: "",
            integral: "",
          };
          emit("reloadData", true);
          state.dialogFormVisible = false;
        });
      }
    };

    return { state, add_submit, props };
  },
};
</script>
